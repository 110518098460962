import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';

export default function Documents() {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const socs = [
    {code:'PAL',lib:'Palmolive'},
    {code:'REX',lib:'Raixona'},
    {code:'BAD',lib:'Badoit'},
  ]

  const projects = [
    {code:'DEM',lib:'Dématerialisation'},
    {code:'AME',lib:'Aménagement locaux'},
    {code:'CHA',lib:'Refonte charte'},
  ]

  const cats = [
    {code:'CA1',lib:'Catégorie 1'},
    {code:'CA2',lib:'Catégorie 2'},
    {code:'CA3',lib:'Catégorie 3'},
  ]
  const budgets = [
    {code:'BU1',lib:'Budget 1'},
    {code:'BU2',lib:'Budget 2'},
    {code:'BU3',lib:'Budget 3'},
  ]

  return (
    <>
    
    <div style={{display:'flex', flexDirection:'row'}}>

    </div>
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
      <InputLabel id="demo-select-small">Société</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
      //  value={age}
        label="Société"
        onChange={handleChange}
        style={{minWidth:'150px'}}
      >
        <MenuItem value=""><em>Aucune</em></MenuItem>
        {socs.map(e=>{
            return(
                <MenuItem value={e.code}>{e.lib}</MenuItem>
            )
        })}
      </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
      <InputLabel id="demo-select-small2">Projet</InputLabel>
      <Select
        labelId="demo-select-small2"
        id="demo-select-small2"
      //  value={age}
        label="Société"
        onChange={handleChange}
        style={{minWidth:'150px'}}
      >
        <MenuItem value=""><em>Aucune</em></MenuItem>
        {projects.map(e=>{
            return(
                <MenuItem value={e.code}>{e.lib}</MenuItem>
            )
        })}
      </Select>
    </FormControl>

    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
      <InputLabel id="demo-select-small2">Catégorie</InputLabel>
      <Select
        labelId="demo-select-small2"
        id="demo-select-small2"
      //  value={age}
        label="Société"
        onChange={handleChange}
        style={{minWidth:'150px'}}
      >
        <MenuItem value=""><em>Aucune</em></MenuItem>
        {cats.map(e=>{
            return(
                <MenuItem value={e.code}>{e.lib}</MenuItem>
            )
        })}
      </Select>
    </FormControl>

    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
      <InputLabel id="demo-select-small2">Budget</InputLabel>
      <Select
        labelId="demo-select-small2"
        id="demo-select-small2"
      //  value={age}
        label="Société"
        onChange={handleChange}
        style={{minWidth:'150px'}}
      >
        <MenuItem value=""><em>Aucun</em></MenuItem>
        {budgets.map(e=>{
            return(
                <MenuItem value={e.code}>{e.lib}</MenuItem>
            )
        })}
      </Select>
    </FormControl>

        {/* <Input>Quantité </Input> */}
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
        <TextField
          id="outlined-number"
          label="Quantité"
          size="small"
        //   type="number"
        style={{minWidth:'50px',maxWidth:'80px'}}
          InputLabelProps={{
            shrink: true,
          }}
        />
 </FormControl>

 <br/>

 <FormControl sx={{ m: 1, minWidth: 120 }} size="big" >
 <TextField
          id="outlined-multiline-flexible"
          label="Description"
          multiline
          maxRows={4}
          //value={value}
          onChange={handleChange}
        />
 </FormControl>

<FormControl sx={{ m: 1, minWidth: 120 }} size="big" >
 <TextField
          id="outlined-multiline-flexible"
          label="Justification"
          multiline
          maxRows={4}
          //value={value}
          onChange={handleChange}
        />
 </FormControl>

    </>
  );
}



// return(
//     <h2>Les détails</h2>
// )
// }