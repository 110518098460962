// import * as React from 'react';
import {useState, uneEffect} from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField  from '@mui/material/TextField';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const card = (
  <>
    <CardContent>


    import TextField  from '@mui/material/TextField';

      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Word of the Day
      </Typography>
      <Typography variant="h5" component="div">
        be{bull}nev{bull}o{bull}lent
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        adjective
      </Typography>
      <Typography variant="body2">
        well meaning and kindly.
        <br />
        {'"a benevolent smile"'}
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small">Learn More</Button>
    </CardActions>
  </>
);



export default function Login({connexion:connexion}) {

    const [log, setLog] = useState({
        login   :'', 
        psw     :'', 
        token   :'',
        envoi:true,
    })

    const handelChange = (e) => {
        let value = e.target.value
        if(e.target.name=='name'){value = value.toUpperCase()}
        if(e.target.name=='code' && value.length >= 4){log.envoi=false}
        setLog({...log, [e.target.name]:value})
    }

const login = () =>{
    console.log("Demande de connexion")
    connexion(true,'')
}

    return (
    <  div
    style={{
        marginTop:'100px',
        display:'Flex',
        flexDirection: 'row',
        justifyContent: 'center',
    }}
    >
    
        <Box sx={{ minWidth: 275 }}> 
            {/* <Card variant="outlined">{card}</Card> */}

        <img src='logo-provea.svg' style={{marginLeft:'-8px'}}></img>

            <h3>Expression des besoins</h3>

            <TextField 
                required
                name='login'
                id="login"
                label="login"
                tittle='Nom de connexion'
                // defaultValue={log.name}
                value={log.name}
                onChange={handelChange}
            /> 
            <p/>

            <TextField 
                required
                type='password'
                id="psw"
                name='psw'
                label="Mot de passe"
                value={log.psw}
                onChange={handelChange}
            />

            <p/>
            <Button type="submit" variant="contained" color="primary" onClick={login}>
            Se connecter
            </Button> 
        </Box>

        {/* <Button size="small">Learn More</Button> */}
    </div>
  );
}