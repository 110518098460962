import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import PauseCircleOutline from '@mui/icons-material/PauseCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

import Detail from './Detail';
import Documents from './Documents';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Expression() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
<div style={{display:'flex',flexDirection:'row', justifyContent:'space-around', maxWidth:'580px'}}>
    <Button  variant="outlined" startIcon={<DoneOutlineOutlinedIcon color="success" />} >ENVOYER</Button>
    <Button variant="outlined" startIcon={<PauseCircleOutline color="warning" />} >Mettre en attente</Button>
    <Button variant="outlined" startIcon={<CloseIcon color="error"/>} >Annuler</Button> 
</div>

    <Box sx={{ width: '100%', }} name='fleur3'>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft:'0px' }}>
        <Tabs value={value}  onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Détail"  {...a11yProps(0)} />
          <Tab label="Documents" {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Detail></Detail>
      </TabPanel>
      <TabPanel value={value} index={1}>
       <Documents></Documents>
      </TabPanel>
      <TabPanel value={value} index={2}>
        --
      </TabPanel>
    </Box>
    </>
  );
}