// import logo from './logo.svg';
// import './App.css';

import {useState, uneEffect} from 'react'

import Login from "./components/Login"
// import Main from "./components/Main"
import Main from "./components/Main"

function App() {

const [state, setState] = useState({
  isLogged:null,
  token:null
})
  
const connexion = (confirmation, token) =>{
  setState({...state, isLogged:confirmation, token:token})
}

return (
 <>

{!state.isLogged && 
  <Login connexion={connexion}/>
}

{state.isLogged && 
  <Main/>
}

 </>
  );
}

export default App;